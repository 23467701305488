<template>
  <div class="sidebar">
    <img :src="require('@/assets/images/businessfuchs.png')" class="sidebar-image" alt="Sidebar Image">
    <div class="cookie-card">
      <p class="description">
        Bedanke Dich <a href="#" @click.prevent="createStripeDonationSession">hier</a> mit einem €<br>
        Beitrag Deiner Wahl!
      </p>
    </div>

    <div class="contact-container">
      <div class="qr-code">
        <img :src="require('@/assets/images/rechnungs-qrcode.png')" alt="QR Code">
      </div>
      <div class="text">
        <img class="whatsapp-logo" :src="require('@/assets/images/whatsapp-logo.png')" alt="WhatsApp Logo">
        <p class="description">Chatte <a href="https://wa.me/4915773013853">hier</a> mit den Entwicklern von Rechnungsfuchs</p>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import ApiService from '@/services/api.service'

export default {
  name: 'SidebarImage',
  data: function () {
    return {
      stripe: null
    }
  },
  async mounted () {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_TOKEN)
  },
  methods: {
    async createStripeDonationSession () {
      try {
        const response = await ApiService.post('/donation/session')

        const { error } = await this.stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        })

        if (error) {
          console.error('Stripe checkout error', error)
        }
      } catch (error) {
        console.error('Error creating subscription session', error)
      }
    }
  }
}
</script>

<style scoped>
.sidebar {
  position: fixed;
  right: 0;
  bottom: 10px;
  width: 300px;
  max-width: 300px;
  z-index: 0;
  padding: 20px 20px 40px;
}

.sidebar-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.cookie-card {
  width: 100%;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 12px;
  background-color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
}

.cookie-card .description {
  padding-top: 10px;
  font-size: 14px;
  margin-left: 10px;
}

.contact-container {
  border-radius: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  font-size: 14px;
  width: 100%;
  overflow: visible;
  height: 150px;
  margin-top: 40px;
}
.qr-code img{
  padding: 5px;
  width: 260px;
}
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -10px;
}

.whatsapp-logo{
  position: relative;
  top: -25px;
  width: 100px;
  height: 100px;
  margin-bottom: 0;
}

.contact-container .description{
  margin-top: -30px;
  margin-bottom: 50px;
  margin-left: 10px;
}

a{
  color: #4A89DC
}
</style>
