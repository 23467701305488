import ApiService from '@/services/api.service'
import { StatusCodes } from 'http-status-codes'
import { BadRequestException } from '@/exceptions/http.exceptions'
import moment from 'moment'
import DatetimeConstants from '@/constants/datetime.constants'
import { CENT_MULTIPLIER } from '@/constants/invoice.constants'

const InvoiceService = {
  createInvoice: async function (data) {
    const formData = new FormData()
    formData.append('data', JSON.stringify(this.prepareInvoiceData(data)))
    formData.append('logoFile', data.logoFile)
    console.log(formData)

    try {
      return await ApiService.post('/invoices/pdf', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(res => {
        return res.data.document_uuid
      })
    } catch (error) {
      console.log(error)
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  createXml: async function (data) {
    try {
      return await ApiService.post('/invoices/xml', {
          data: this.prepareInvoiceData(data)
        }
      ).then(res => {
        return res.data.document_uuid
      })
    } catch (error) {
      console.log(error)
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  readInvoiceDocument: async function (documentUuid) {
    try {
      return await ApiService.get(`/document/${documentUuid}`,
          {
            responseType: 'blob'
          }
      ).then(res => {
        return new Blob(
            [res.data],
            { type: 'application/pdf' })
      })
    } catch (error) {
      if (error.response.status === StatusCodes.BAD_REQUEST) {
        throw new BadRequestException(error)
      }

      throw error
    }
  },
  prepareInvoiceData: function (data) {
    return {
      biller_name: data.biller.name,
      biller_street: data.biller.street,
      biller_postal_code: data.biller.postalCode,
      biller_city: data.biller.city,
      biller_country: data.biller.country,
      biller_country_code: 'de',
      customer_address_plain: data.customerAddressPlain,
      customer_country_code: data.customerCountryCode,
      subject: data.subject,
      invoice_no: data.invoiceNo,
      invoice_date: moment(data.invoiceDate).format(DatetimeConstants.DateFormat),
      invoice_delivery_date: this.resolveInvoiceDate(data.invoiceDeliveryDate),
      invoice_delivery_date_until: this.resolveInvoiceDateUntil(data.invoiceDeliveryDate),
      head_text: data.headText,
      bottom_text: data.bottomText,
      currency: data.currency,
      invoice_items: this.prepareInvoiceItems(data.invoiceItems),
      contact_data_plain: data.contactDataPlain,
      legal_details_plain: data.legalDetailsPlain,
      bank_details_plain: data.bankDetailsPlain,
      invoice_type: data.invoiceType
    }
  },
  prepareInvoiceItems: function (items) {
    return items.map((item) => {
      return {
        position: item.position,
        description: item.description,
        quantity: item.quantity.toString(),
        unit: item.unit,
        unit_price: parseInt((item.unitPrice * CENT_MULTIPLIER).toFixed(0)),
        vat_percentage: item.vatPercentage
      }
    })
  },
  resolveInvoiceDate: function (invoiceDeliveryDate) {
    if (Array.isArray(invoiceDeliveryDate)) {
      return moment(invoiceDeliveryDate[0]).format(DatetimeConstants.DateFormat)
    }

    return moment(invoiceDeliveryDate).format(DatetimeConstants.DateFormat)
  },
  resolveInvoiceDateUntil (invoiceDeliveryDate) {
    if (Array.isArray(invoiceDeliveryDate)) {
      return moment(invoiceDeliveryDate[1]).format(DatetimeConstants.DateFormat)
    }
    return null
  }
}

export default InvoiceService
